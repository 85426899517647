import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook8/dataPageEbook8"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        {" "}
        Guía para una buena experiencia de compra del cliente - 2022 
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/experiencia-compra-cliente-ebook/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Te enseñamos cómo generar una buena experiencia de compra dentro de tu negocio, para que así puedas aumentar tus ventas y fidelizar tus clientes.    "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Guía para una buena experiencia de compra del cliente - 2022 "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/experiencia-compra-cliente-ebook/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Ebook/og-imgEbook8.jpg`}
      />
      <meta
        property="og:description"
        content="Te enseñamos cómo generar una buena experiencia de compra dentro de tu negocio, para que así puedas aumentar tus ventas y fidelizar tus clientes.    "
      />
    </Helmet>
    <TemplatePageEbook ebook="Atención al Cliente" location={location} typeEbook="ebook8" clickHereHref="https://beexcc.com/blog/ecommerce-news/" data={data} />
  </div>
)

export default IndexPage
